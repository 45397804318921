import React, { useState, useEffect } from 'react';
import './Blog.css';

const Blog = () => {
  const [blogs, setBlogs] = useState([
  {
    id: 1,
    title: 'Ashwini Nakshatra: The Nakshatra of Beauty, Intelligence, and Independence',
    content: 'ज्योतिष शास्त्र में सबसे प्रमुख और सबसे प्रथम अश्विनी नक्षत्र को माना गया है। अश्विनी नक्षत्र में जन्मे जातक सामान्यतः सुन्दर, चतुर, सौभाग्यशाली एवं स्वतंत्र विचारों वाले और आधुनिक सोच के लिए मित्रों में प्रसिद्ध होते हैं। इस नक्षत्र में जन्मा व्यक्ति बहुत ऊर्जावान होने के साथ-साथ सदा सक्रिय रहता है। इनकी महत्वाकांक्षाएं इन्हें संतुष्ट नहीं होने देतीं। ये लोग सभी से बहुत प्रेम करने वाले, हस्तक्षेप न पसंद करने वाले, रहस्यमयी प्रवृत्ति के होते हैं। ये लोग अच्छे जीवनसाथी और एक आदर्श मित्र साबित होते हैं।'
  },
  {
    id: 2,
    title: 'Bharani Nakshatra: The Nakshatra of Determination, Charm, and Social Status',
    content: 'इस नक्षत्र का स्वामी शुक्र ग्रह होता है, जिस कारण इस नक्षत्र में जन्मे लोग एक दृढ़ निश्चयी, चतुर, सदा सत्य बोलने वाले, आराम पसंद और आलीशान जीवन जीने वाले होते हैं। ये लोग काफी आकर्षक और सुंदर होते हैं, इनका स्वभाव लोगों को आकर्षित करता है। इनके अनेक मित्र होंगे और मित्रों में बहुत अधिक लोकप्रिय भी होते हैं। इनके जीवन में प्रेम सर्वोपरी होता है और जो भी ये ठान लेते हैं उसे पूरा करने के बाद ही चैन से बैठते हैं। इनकी सामाजिक प्रतिष्ठा और सम्मान हमेशा बना रहता है।'
  },
  {
    id: 3,
    title: 'Krittika Nakshatra: The Nakshatra of Self-Esteem, Beauty, and Musical Talent',
    content: 'इस नक्षत्र में जन्म लेने वाले व्यक्ति पर सूर्य का प्रभाव रहता है, जिस कारण से ये लोग आत्म गौरव करने वाले होते हैं। इस नक्षत्र में जन्मे जातक सुन्दर और मनमोहक छवि वाला होता है। वह केवल सुन्दर ही नहीं अपितु गुणी भी होते हैं। ना तो पहली नजर में प्यार जैसी चीज पर भरोसा करते हैं और ना ही किसी पर बहुत जल्दी विश्वास करते हैं। इन लोगों का व्यक्तित्व राजा के समान ओजपूर्ण एवं पराक्रमी होता है। ये तेजस्वी एवं तीक्ष्ण बुद्धि के स्वामी, स्वाभिमानी, तुनक मिजाजी और बहुत उत्साहित रहने वाले होते हैं। ये लोग जिस भी काम को अपने हाथ में लेते हैं उसे पूरी लगन और मेहनत के साथ पूरा करते हैं।'
  },
  {
    id: 4,
    title: 'Rohini Nakshatra: The Nakshatra of Imagination, Changeability, and Love',
    content: 'रोहिणी नक्षत्र का स्वामी चंद्रमा होता है और चंद्रमा के प्रभाव के कारण ये लोग बहुत कल्पनाशील और मनचले स्वभाव के होते हैं। ये लोग काफी चंचल स्वभाव के होते हैं और स्थायित्व इन्हें रास नहीं आता। इन लोगों की सबसे बड़ी कमी यह होती है कि ये कभी एक ही मुद्दे या राय पर कायम नहीं रहते। ये व्यक्ति सदा दूसरों में गलतियां ढूँढते रहते हैं। सामने वाले की त्रुटियों की चर्चा करना इनका शौक होता है। ये लोग स्वभाव से बहुत मिलनसार तो होते ही हैं लेकिन साथ-साथ जीवन की सभी सुख-सुविधाओं को पाने का प्रयास भी करते रहते हैं। विपरीत लिंग के लोगों के प्रति इनके अन्दर विशेष आकर्षण देखा जा सकता है। ये शारीरिक रूप से कमज़ोर होते हैं इसलिए कोई भी छोटे से छोटे वातावरण बदलाव के रोग भी आपको अक्सर जकड़ लेते हैं।'
  },
  {
    id: 5,
    title: 'Mrigashira Nakshatra: The Nakshatra of Courage, Determination, and Physical Strength',
    content: 'इस नक्षत्र के जातकों पर मंगल का प्रभाव होने के कारण ये लोग स्वभाव से बहुत साहसी, दृढ़ निश्चय चतुर एवं चंचल स्वभाव के अध्ययन में अधिक रूचि, माता पिता के आज्ञाकारी और सदैव साफ़ सुथरे आकर्षक वस्त्र पहनने वाले होते हैं। ये लोग स्थायी जीवन जीने में विश्वास रखते हैं और हर काम पूरी मेहनत के साथ पूरा करते हैं। इनका व्यक्तित्व बहुत आकर्षक होता है और ये हमेशा सचेत रहते हैं। यदि कोई व्यक्ति इनके साथ धोखा करता है तो ये किसी भी कीमत पर उसे सबक सिखाकर ही मानते हैं। बुद्धिमान होने के साथ-साथ मानसिक रूप से मजबूत होते हैं। इनको संगीत पसंद होता है और सांसारिक सुखों का उपभोग करने वाले होते हैं।'
  },
  {
    id: 6,
    title: 'Ardra Nakshatra: The Nakshatra of Intelligence, Cunning, and Business Acumen',
    content: 'इस नक्षत्र में जन्म लेने वाले लोगों पर आजीवन बुध और राहु ग्रह का प्रभाव रहता है। राहु का प्रभाव इन्हें राजनीति की ओर लेकर जाता है और इनके प्रति दूसरों में आकर्षण विकसित करता है। अध्ययन में रूचि अधिक और किताबों से विशेष लगाव आपकी पहचान होगी। सदैव ही अपने आस-पास की घटनाओं के बारे में जागरूक और व्यापार करने की समझ इनकी महान विशेषता है। ये लोग दूसरों का दिमाग पढ़ लेते हैं इसलिए इन्हें बहुत सरलता से बेवकूफ नहीं बनाया जा सकता। दूसरों से काम निकलवाने में कलाकार होते हैं इस नक्षत्र में जन्मे लोग अपने निजी स्वार्थ को पूरा करने के लिए नैतिकता को भी छोड़ देते हैं।'
  },
  {
    id: 7,
    title: 'Punarvasu Nakshatra: The Nakshatra of Divine Blessings, Wealth, and Hidden Enemies',
    content: 'इस नक्षत्र में जन्म लेने वाले व्यक्ति के भीतर दैवीय शक्तियां होती हैं। पुनर्वसु नक्षत्र में जन्मे व्यक्ति बेहद मिलन सार, दूसरों से प्रेमपूर्वक व्यवहार रखने वाले होते हैं। इनका शरीर काफी भारी और याद्दाश्त बहुत मजबूत होती है। ये लोग काफी मिलनसार और प्रेम भावना से ओत-प्रोत होते हैं। आप कह सकते हैं कि जब भी इन पर कोई विपत्ति आती है तो कोई अदृश्य शक्ति इनकी सहायता करने अवश्य आती है। ये लोग काफी धनी भी होते हैं। आपके गुप्त शत्रुओं की संख्या अधिक होती है।'
  },
  ]);
  const [expandedIndex, setExpandedIndex] = useState(-1);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://jaideepastrovastu-com.onrender.com/api/blogs');
        const data = await response.json();

        setBlogs((prevBlogs) => [...prevBlogs, ...data]);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  const toggleExpanded = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  return (
    <div className="blog-main-container">
      <h1 className="heading">My Blogs</h1>
      {blogs.map((blog, index) => (
        <div className="blog" key={blog._id}>
          <h3 className="blog-heading">
            {blog.title}
          </h3>
          <p className={`blog-detail ${expandedIndex === index ? 'expanded' : ''}`}>
            {expandedIndex !== index ? `${blog.content.split(' ').slice(0, 25).join(' ')}...` : blog.content}
          </p>
          <button className="learn-more-button" onClick={() => toggleExpanded(index)}>
            {expandedIndex === index ? 'Show Less' : 'Learn More'}
          </button>
        </div>
      ))}
    </div>
  );
};

export default Blog;
